/*
| Developed by Starton
| Filename : ErrorPageTemplate.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { styled, Box, Typography, Avatar, lighten, useTheme } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { StartonButton } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from 'next/link'
import { ErrorLinkedinLogo, ErrorTwitterLogo, ErrorDiscordLogo } from '../../pages/errors'
import { UrlsConfig } from '@/config/urls/urls.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ErrorTemplateProps {
	code: string | React.ReactNode
	messageTitle: string
	messageDescription?: string
	charFace: string
	color: 'success' | 'warning' | 'error' | 'info'
	showBackToDashboardButton?: boolean
	secondaryButton?: React.ReactNode
	serviceVue?: boolean
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const TemplateContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	minHeight: '100vh',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: theme.palette.background.paper,
}))

const ErrorWindowSnackbar = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'color',
})<{ type: 'success' | 'warning' | 'error' | 'info' }>(({ theme, type }) => ({
	width: '100%',
	height: theme.spacing(10),
	display: 'flex',
	alignItems: 'center',
	gap: '14px',
	padding: theme.spacing(0, 3),
	color: theme.palette.warning.dark,
	backgroundColor: lighten(theme.palette[type].main, 0.8),
}))

const ErrorWindowBody = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: theme.spacing(5),
	height: 'calc(100vh - (32px + 56px + 96px))',
	textAlign: 'center',
	[theme.breakpoints.up('md')]: {
		width: '50%',
	},
}))

const ErrorWindowIcon = styled(Avatar)(({ theme }) => ({
	backgroundColor: 'initial',
	width: '100%',
	height: '100%',
	maxHeight: 400,
	padding: theme.spacing(2),
	'& svg': {
		width: '100%',
		height: '100%',
	},
}))

const ErrorFooter = styled(Box)(({ theme }) => ({
	height: theme.spacing(12),
	display: 'flex',
	borderTop: `1px solid ${theme.palette.divider}`,
	width: '100%',
	alignItems: 'center',
	justifyContent: 'center',
}))

const ErrorFooterLogo = styled(Avatar)({
	width: 32,
	height: 32,
	backgroundColor: 'transparent',
	'& svg': {
		width: 32,
		height: 32,
	},
})

/*
|--------------------------------------------------------------------------
| Sub Component
|--------------------------------------------------------------------------
*/
const Logos = () => {
	const logos = [
		{
			url: UrlsConfig.social.discord,
			component: <ErrorDiscordLogo />,
		},
		{
			url: UrlsConfig.social.linkedin,
			component: <ErrorLinkedinLogo />,
		},
		{
			url: UrlsConfig.social.twitter,
			component: <ErrorTwitterLogo />,
		},
	]

	return (
		<Box display={'flex'} gap={3}>
			{logos.map((item, index) => (
				<Link href={item.url} passHref legacyBehavior key={index} data-testid={`social-link-${index}`}>
					<a target="_blank">
						<ErrorFooterLogo>{item.component}</ErrorFooterLogo>
					</a>
				</Link>
			))}
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ErrorPageTemplate: React.FC<ErrorTemplateProps> = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const sm = useMediaQuery(theme.breakpoints.down('sm'))
	const md = useMediaQuery(theme.breakpoints.down('md'))
	const {
		code,
		messageTitle,
		messageDescription,
		charFace,
		color,
		showBackToDashboardButton,
		secondaryButton,
		serviceVue,
	} = props

	return (
		<TemplateContainer>
			{!serviceVue ? (
				<ErrorWindowSnackbar type={color}>
					<WarningAmber color={color} />
					<Typography fontSize={'16px'} fontWeight={700} sx={{ color: (theme) => theme.palette[color].dark }}>
						{messageTitle}
					</Typography>
				</ErrorWindowSnackbar>
			) : null}
			<ErrorWindowBody>
				{typeof code === 'string' ? (
					<Typography
						variant={'h2'}
						fontSize={sm ? 125 : md ? 200 : 250}
						lineHeight={'256px'}
						marginBottom={'-25px'}
					>
						{code}
					</Typography>
				) : (
					<ErrorWindowIcon variant={'square'}>{code}</ErrorWindowIcon>
				)}
				<Typography variant={'h2'} whiteSpace={'pre-line'} lineHeight={2}>
					{messageTitle}
				</Typography>
				{messageDescription ? <Typography variant={'body2'}>{messageDescription}</Typography> : null}
				<Typography color={`${color}.lighter`} fontSize={20} lineHeight={1} data-testid="char-face">
					{charFace}
				</Typography>
				<Box display={'flex'} gap={2}>
					{secondaryButton}
					{showBackToDashboardButton ? (
						<Link href={UrlsConfig.home} data-testid="back-to-dashboard">
							<StartonButton variant={'contained'} color={'primary'} size="large">
								{t('common:common.backToDashboard')}
							</StartonButton>
						</Link>
					) : null}
				</Box>
				{serviceVue ? <Logos /> : null}
			</ErrorWindowBody>
			{!serviceVue ? (
				<ErrorFooter>
					<Logos />
				</ErrorFooter>
			) : null}
		</TemplateContainer>
	)
}
